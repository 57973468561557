import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { X } from 'phosphor-react';
import './popup.css';
import { changeFormType, changeTrigger } from 'Features/login';

function Popup({ children, trigger }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = (e) => {
      e.preventDefault();
      window.scrollTo(0, window.scrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [trigger]);

  return trigger ? (
    <div className='popup'>
      <div className='popup-inner' style={{ minHeight: '200px', minWidth: '300px' }}>
        <div className='h-100'>
          <div
            role='button'
            tabIndex={0}
            className='popup-close'
            onClick={() => {
              dispatch(changeTrigger());
              dispatch(changeFormType('login'));
            }}
          >
            <X size={23} color='#000' />
          </div>
          <div className='h-100'>{React.cloneElement(children)}</div>
        </div>
      </div>
      <div
        className='popup-outer'
        role='button'
        tabIndex={0}
        onClick={() => dispatch(changeTrigger())}
      />
    </div>
  ) : (
    ''
  );
}

const mapStateToProps = (state) => ({
  trigger: state.login.trigger,
  formType: state.login.formType,
});

export default connect(mapStateToProps)(Popup);
