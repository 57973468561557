import * as Yup from 'yup';

export const signUpSchema = Yup.object({
  firstname: Yup.string()
    .matches(/^[a-zA-Z ]+$/, 'Please enter a valid first name')
    .min(2, 'First Name must be at least 2 characters')
    .max(60)
    .required('Please Enter First Name'),
  lastname: Yup.string()
    .matches(/^[a-zA-Z ]+$/, 'Please enter a valid last name')
    .max(60)
    .required('Please Enter Last Name'),
  phone: Yup.string()
    .test(
      'is-valid',
      'Please enter a valid mobile number',
      (value) => /^[5-9]\d{9}$/.test(value) && value.length === 10,
    )
    .required('Please Enter Mobile Number'),
  pincode: Yup.string()
    .matches(/^\d{6}$/, 'Pincode must be 6 digits')
    .required('Please Enter Pin Code'),
  address: Yup.string()
    .matches(/^[a-zA-Z0-9\s.,'-]+$/, 'Please enter a valid address')
    .min(5)
    .max(256)
    .required('Please Enter Address')
    .trim(),
  city: Yup.string()
    .matches(/^[a-zA-Z.\s-]+$/, 'Please enter a valid city name')
    .required('Please Enter City'),
  state: Yup.object().notOneOf([{}], 'State cannot be empty').required('Please Select The State'),
});

export const checkoutSchema = Yup.object({
  firstname: Yup.string()
    .trim()
    .matches(/^[a-zA-Z ]+$/, 'Please enter a valid first name')
    .min(2, 'First Name must be at least 2 characters')
    .max(60)
    .required('Please Enter First Name'),
  lastname: Yup.string()
    .trim()
    .matches(/^[a-zA-Z ]+$/, 'Please enter a valid last name')
    .max(60)
    .required('Please Enter Last Name'),
  phone: Yup.string()
    .test(
      'is-valid',
      'Please enter a valid mobile number',
      (value) => /^[5-9]\d{9}$/.test(value) && value.length === 10,
    )
    .required('Please Enter Mobile Number'),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Invaild Email ID',
    )
    .required('Please Enter Email ID'),
  pincode: Yup.string()
    .matches(/^\d{6}$/, 'Pincode must be 6 digits')
    .required('Please Enter Pin Code'),
  address: Yup.string()
    .matches(/^[a-zA-Z0-9\s.,'-]+$/, 'Please enter a valid address')
    .min(5)
    .max(256)
    .required('Please Enter Address'),
  city: Yup.string()
    .matches(/^[a-zA-Z.\s-]+$/, 'Please enter a valid city name')
    .required('Please Enter City'),
  state: Yup.object().notOneOf([{}], 'State cannot be empty').required('Please Select The State'),
  virtualParticipants: Yup.array().of(
    Yup.object().shape({
      firstname: Yup.string()
        .matches(/^[a-zA-Z ]+$/, 'Please enter a valid first name')
        .min(2, 'First Name must be at least 2 characters')
        .max(60)
        .required('Please Enter First Name')
        .trim(),
      lastname: Yup.string()
        .matches(/^[a-zA-Z ]+$/, 'Please enter a valid last name')
        .max(60)
        .required('Please Enter Last Name')
        .trim(),
    }),
  ),
});

export const b2bUserSchema = Yup.object({
  firstname: Yup.string()
    .trim()
    .matches(/^[a-zA-Z ]+$/, 'Please enter a valid first name')
    .min(2, 'First Name must be at least 2 characters')
    .max(60)
    .required('Please Enter First Name'),
  lastname: Yup.string()
    .trim()
    .matches(/^[a-zA-Z ]+$/, 'Please enter a valid last name')
    .max(60)
    .required('Please Enter Last Name'),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Invaild Email ID',
    )
    .required('Please Enter Email ID'),
  mobile: Yup.string()
    .test(
      'is-valid',
      'Please enter a valid mobile number',
      (value) => /^[5-9]\d{9}$/.test(value) && value.length === 10,
    )
    .required('Please Enter Mobile Number'),
});

export const b2cUserSchema = Yup.object({
  firstname: Yup.string()
    .trim()
    .matches(/^[a-zA-Z ]+$/, 'Please enter a valid first name')
    .min(2, 'First Name must be at least 2 characters')
    .max(60)
    .required('Please Enter First Name'),
  lastname: Yup.string()
    .trim()
    .matches(/^[a-zA-Z ]+$/, 'Please enter a valid last name')
    .max(60)
    .required('Please Enter Last Name'),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Invalid Email ID',
    )
    .required('Please Enter Email ID'),
  mobile: Yup.string()
    .test(
      'is-valid',
      'Please enter a valid mobile number',
      (value) => /^[5-9]\d{9}$/.test(value) && value.length === 10,
    )
    .required('Please Enter Mobile Number'),
  gender: Yup.mixed().oneOf([1, 4, 7], 'Please Select Gender').notRequired(),
  dateOfBirth: Yup.date()
    .nullable()
    .notRequired()
    .max(new Date(), "Date of birth can't be in the future")
    .min(new Date('1900-01-01'), 'Date of birth must be after January 1st, 1900')
    .test(
      'age',
      'You must be at least 14 years old',
      (value) => !value || new Date().getFullYear() - new Date(value).getFullYear() >= 14,
    ),
});

export const b2bBusinessSchema = Yup.object({
  company: Yup.string()
    .trim()
    .matches(/^[a-zA-Z ]+$/, 'Please enter a valid business name')
    .min(2, 'First Name must be at least 2 characters')
    .required('Please Enter Business Name'),
  address: Yup.string()
    .min(5)
    .max(256, 'Address must be at most 256 characters')
    .required('Please Enter Address')
    .trim(),
});

export const LoginSchema = Yup.object({
  phone: Yup.string()
    .test(
      'is-valid',
      'Please enter a valid mobile number',
      (value) => /^[5-9]\d{9}$/.test(value) && value.length === 10,
    )
    .required('Please Enter Mobile Number'),
  tnc: Yup.boolean().oneOf([true], 'Please check this to continue'),
});

export const changeEmailPop = Yup.object({
  newEmail: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Invaild Email ID',
    )
    .required('Please enter Email ID'),
});

export const changeMobilePop = Yup.object({
  number: Yup.string()
    .test(
      'is-valid',
      'Please enter a valid mobile number',
      (value) => /^[5-9]\d{9}$/.test(value) && value.length === 10,
    )
    .required('Please Enter Mobile Number'),
});

export const validationOfProfile = Yup.object({
  firstName: Yup.string().required('Please enter first Name'),
  lastName: Yup.string().required('Please enter Last Name'),
  gender: Yup.mixed().oneOf([0, 1], 'Please Select Gender').required(),
  dateOfBirth: Yup.date()
    .max(new Date(), "Date of birth can't be in the future")
    .min(new Date('1900-01-01'), 'Date of birth must be after January 1st, 1900')
    .test(
      'age',
      'You must be at least 14 years old',
      (value) => new Date().getFullYear() - new Date(value).getFullYear() >= 14,
    )
    .required('Date of birth is required'),
});

export const registerSchema = Yup.object({
  firstname: Yup.string()
    .matches(/^[a-zA-Z ]+$/, 'Please enter a valid first name')
    .min(2, 'First Name must be at least 2 characters')
    .max(60)
    .required('Please enter First Name'),
  lastname: Yup.string()
    .matches(/^[a-zA-Z ]+$/, 'Please enter a valid last name')
    .max(60)
    .required('Please enter Last Name'),
  phone: Yup.string()
    .test(
      'is-valid',
      'Please enter a valid mobile number',
      (value) => /^[5-9]\d{9}$/.test(value) && value.length === 10,
    )
    .required('Please Enter Mobile Number'),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Please enter a valid Email Id')
    .required('Please enter Email Id'),
  choice1: Yup.boolean().oneOf([true], 'Please Check This'),
});
