import React from 'react';
import { connect } from 'react-redux';
import Register from './Register';
import SignIn from './SignIn';
import SigninWithPassword from './SigninWithPassword';
import VerifyOtp from './VerifyOtp';
import VerifyOtpForgotPassword from './VerifyOtpForgotPassword';

const Form = ({ formType }) => {
  switch (formType) {
    case 'login':
      return <SignIn />;
    case 'verfiy_otp':
      return <VerifyOtp />;
    case 'login_with_password':
      return <SigninWithPassword />;
    case 'register':
      return <Register />;
    case 'VerifyOtpForgotPassword':
      return <VerifyOtpForgotPassword />;
    default:
      break;
  }
};

const mapStateToProps = (state) => ({
  formType: state.login.formType,
});

export default connect(mapStateToProps)(Form);
