/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'Utils/Toast';
import { setLoading } from 'Features/GlobalLoading/LoadingSlice';
import { VIRTUAL_CART_ITEM } from 'Utils/Strings';
import { getProfileCompletion } from 'Utils/getProfileCompletion';
import cartServices from './cartServices';

const initialState = {
  vpCartID: '',
  cart: [],
  userFirstName: '',
  userLastName: '',
  userMobileNumber: '',
  userEmail: '',
  userGender: '',
  userDob: '',
  userID: '',
  cartCount: 0,
  cartID: '',
  loading: false,
  status: '',
  message: '',
  pincode: '',
  coupons: [],
  offer: null,
  appliedCoupons: null,
  rewardPoints: 0,
  minimumShipping: {},
  loyaltyPoints: 0,
  promoCredit: null,
  usedPointsInValue: 0,
  usedPoints: 0,
  giftOption: {},
  priceDetails: null,
  giftWrapping: null,
  freeShipping: false,
  shippingAmount: 0,
  bagDiscount: 0,
  shippingAddress: [],
  shippingCharge: 0,
  loadin: false,
  codAmount: 0,
  razorpayAmount: null,
  couponErrMsg: '',
  saveForLater: [],
  swirlError: '',
  profileCompletion: '',
  gbWallet: {},
  totalSavings: 0,
  gstEarn: 0,
  hasNoGstNum: false,
  hasGlobalBrand: false,
  hasfetchedCartdetails: false,
};

export const createEmptyCart = createAsyncThunk(
  'cart/createEmptyCart',
  async (_, { rejectWithValue }) => {
    try {
      return await cartServices.createEmptyCartId();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getCustomerCartId = createAsyncThunk(
  'cart/getCustomerCartId',
  async (_, { rejectWithValue }) => {
    try {
      return await cartServices.getCustomerCartId();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const assignCustomerCart = createAsyncThunk(
  'cart/assignCustomerCart',
  async (_, { getState, rejectWithValue }) => {
    try {
      const assign = await cartServices.assignCustomerCart(getState());
      return assign;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getCustomerCartCount = createAsyncThunk(
  'cart/getCustomerCartCount',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      return await cartServices.getCartCount(getState());
    } catch (error) {
      if (
        error.message.toLowerCase().includes('cartid') ||
        error.message.toLowerCase().includes('active') ||
        error.message.toLowerCase().includes('could not find a cart')
      ) {
        await dispatch(createEmptyCart());
        const count = await cartServices.getCartCount(getState());
        return count;
      }
      return rejectWithValue(error.message);
    }
  },
);

export const getOnlyCartDetails = createAsyncThunk(
  'cart/getOnlyCartDetails',
  async (_, { getState, rejectWithValue }) => {
    try {
      const details = await cartServices.getCartDetails(getState());
      return await details;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getCartDetails = createAsyncThunk(
  'cart/getCartDetails',
  async (vpId, { getState, rejectWithValue, dispatch }) => {
    try {
      // only logged in user have the loyalty point.
      if (localStorage.getItem('token')) {
        await Promise.all([
          dispatch(getLoyaltyPoints()),
          dispatch(getCartStoreConfig()),
          dispatch(getFreeProducts()),
        ]);
      } else {
        Promise.all([dispatch(getCartStoreConfig()), dispatch(getFreeProducts())]).catch(
          (error) => {
            throw new Error(error);
          },
        );
      }

      return await cartServices.getCartDetails(getState(), vpId);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const cartDetailsOnFirstLoad = createAsyncThunk(
  'cart/cartDetailsOnFirstLoad',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      // only logged in user have the loyalty point.
      if (localStorage.getItem('token')) {
        await Promise.all([
          dispatch(getCartStoreConfig()),
          dispatch(getLoyaltyPoints()),
          dispatch(getFreeProducts()),
        ]).catch((error) => {
          throw new Error(error);
        });
      } else {
        Promise.all([dispatch(getCartStoreConfig())]).catch((error) => {
          throw new Error(error);
        });
      }
      const allData = await cartServices.getCartDetails(getState());
      return allData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const setShippingAddress = createAsyncThunk(
  'cart/setShippingAddress',
  async (data, { getState, rejectWithValue }) => {
    try {
      const setShippingAddress = await cartServices.setShippingAddress(getState(), data);
      return setShippingAddress;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateShippingAddress = createAsyncThunk(
  'cart/updateShippingAddress',
  async (_, { getState, rejectWithValue }) => {
    try {
      const ShippingAddress = await cartServices.updateShippingAddress(getState());
      return ShippingAddress;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const removeFromCart = createAsyncThunk(
  'cart/removeFromCart',
  async (uid, { getState, rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const removed = await cartServices.removeItem(getState(), uid);
      await Promise.all([dispatch(getFreeProducts())]);
      if (localStorage.getItem('token')) {
        await Promise.all([dispatch(getLoyaltyPoints())]);
      }
      return removed;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(setLoading(false));
    }
  },
);

export const getCartStoreConfig = createAsyncThunk(
  'cart/getCartStoreConfig',
  async (_, { rejectWithValue }) => {
    try {
      return await cartServices.getCartStoreConfig();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getLoyaltyPoints = createAsyncThunk(
  'cart/getLoyaltyPoints',
  async (_, { getState, rejectWithValue }) => {
    try {
      return await cartServices.getLoyaltyPoints(getState());
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addLoyaltyPoints = createAsyncThunk(
  'cart/addLoyaltyPoints',
  async (points, { getState, rejectWithValue, dispatch }) => {
    try {
      const added = await cartServices.addLoyaltyPoints(getState(), points);
      await Promise.all([dispatch(getLoyaltyPoints())]);
      return added;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addPromotionalAmount = createAsyncThunk(
  'cart/addPromotionalAmount',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const added = await cartServices.addPromotionalAmount(getState());
      await Promise.all([dispatch(getLoyaltyPoints())]);
      return added;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const removePromotionalAmount = createAsyncThunk(
  'cart/removePromotionalAmount',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const removed = await cartServices.removePromotionalAmount(getState());
      await Promise.all([dispatch(getLoyaltyPoints())]);
      return removed;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addGbWallet = createAsyncThunk(
  'cart/addGbWallet',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const added = await cartServices.addGbWallet(getState());
      await Promise.all([dispatch(getLoyaltyPoints())]);
      return added;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const removeGbWallet = createAsyncThunk(
  'cart/removeGbWallet',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const removed = await cartServices.removeGbWallet(getState());
      await Promise.all([dispatch(getLoyaltyPoints())]);
      return removed;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addToCart = createAsyncThunk(
  'cart/addToCart',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const added = await cartServices.addToCart(getState(), data);
      return added;
    } catch (error) {
      if (
        error.message.toLowerCase().includes('cartid') ||
        error.message.toLowerCase().includes('active') ||
        error.message.toLowerCase().includes('could not find a cart')
      ) {
        await dispatch(createEmptyCart());
        const added = await cartServices.addToCart(getState(), data);
        return added;
      }
      return rejectWithValue(error.message);
    }
  },
);

export const addToCartWithQuantity = createAsyncThunk(
  'cart/addToCartWithQuantity',
  async (data, { getState, rejectWithValue }) => {
    try {
      const added = await cartServices.addToCartWithQuantity(getState(), data);
      return added;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addMultipleToCart = createAsyncThunk(
  'cart/addMultipleToCart',
  async (data, { getState, rejectWithValue }) => {
    try {
      const added = await cartServices.addMultipleToCart(getState(), data);
      return added;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getGiftDetails = createAsyncThunk(
  'cart/getGiftDetails',
  async (_, { getState, rejectWithValue }) => {
    try {
      return await cartServices.getGiftDetails(getState());
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addGiftOptions = createAsyncThunk(
  'cart/addGiftOptions',
  async (uid, { getState, rejectWithValue, dispatch }) => {
    try {
      const added = await cartServices.addGiftOptions(getState(), uid);
      if (localStorage.getItem('token')) {
        await Promise.all([dispatch(getLoyaltyPoints())]);
      }
      return added;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const removeGiftOptions = createAsyncThunk(
  'cart/removeGiftOptions',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const removed = await cartServices.removeGiftOptions(getState());
      if (localStorage.getItem('token')) {
        await Promise.all([dispatch(getLoyaltyPoints())]);
      }
      return removed;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateCartItem = createAsyncThunk(
  'cart/updateCartItem',
  async (item, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const updatedData = await cartServices.updateItem(getState(), item);
      await Promise.all([dispatch(getFreeProducts())]);
      if (localStorage.getItem('token')) {
        await Promise.all([dispatch(getLoyaltyPoints())]);
      }
      dispatch(setLoading(false));
      return updatedData;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  },
);

export const getCartCoupons = createAsyncThunk(
  'cart/getCartCoupons',
  async (_, { getState, rejectWithValue }) => {
    try {
      return await cartServices.getCoupons(getState());
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const applyCartCoupons = createAsyncThunk(
  'cart/applyCartCoupons',
  async (args, { getState, rejectWithValue, dispatch }) => {
    const [couponText, vpId] = args;
    try {
      dispatch(setLoading(true));
      const applyCoupons = await cartServices.applyCoupons(getState(), couponText, vpId);
      await Promise.all([dispatch(getLoyaltyPoints())]);
      dispatch(setLoading(false));
      return applyCoupons;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  },
);

export const applyMultipleCartCoupons = createAsyncThunk(
  'cart/applyMultipleCartCoupons',
  async (args, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const applyCoupons = await cartServices.applyMultipleCoupons(getState(), args);
      await Promise.all([dispatch(getLoyaltyPoints())]);
      dispatch(setLoading(false));
      return applyCoupons;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  },
);

export const removeCartCoupons = createAsyncThunk(
  'cart/removeCartCoupons',
  async (vpId, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const removeCoupon = await cartServices.removeCoupons(getState(), vpId);
      if (localStorage.getItem('token')) {
        await Promise.all([dispatch(getLoyaltyPoints())]);
      }
      dispatch(setLoading(false));
      return removeCoupon;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  },
);

export const removeMultipleCartCoupons = createAsyncThunk(
  'cart/removeMultipleCartCoupons',
  async (args, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const removeCoupon = await cartServices.removeMultipleCoupons(getState(), args);
      if (localStorage.getItem('token')) {
        await Promise.all([dispatch(getLoyaltyPoints())]);
      }
      dispatch(setLoading(false));
      return removeCoupon;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  },
);

export const quickBuyBySkuWithQty = createAsyncThunk(
  'cart/quickBuyBySku',
  async (data, { rejectWithValue }) => {
    try {
      const response = await cartServices.quickBuyBySkuWithQty(data);
      return response.data.quickBuyBySku.cart;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const quickBuyBySku = createAsyncThunk(
  'cart/quickBuyBySku',
  async (data, { rejectWithValue }) => {
    try {
      const response = await cartServices.quickBuyBySku(data);
      return response.data.quickBuyBySku.cart;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const retriveCart = createAsyncThunk(
  'cart/retriveCart',
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await cartServices.retriveCart(getState());
      return response.data.retrivecartbyid.cart;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
export const addSaveForLater = createAsyncThunk(
  'cart/addSaveForLater',
  async (itemData, { getState, rejectWithValue, dispatch }) => {
    try {
      const addSaveForLater = await cartServices.addSaveForLater(itemData, getState());
      await Promise.all([dispatch(getFreeProducts()), dispatch(getLoyaltyPoints())]);
      return addSaveForLater;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addMultipleSaveForLater = createAsyncThunk(
  'cart/addMultipleSaveForLater',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const addMultipleSaveForLater = await cartServices.addMultipleSaveForLater(getState());
      await Promise.all([dispatch(getFreeProducts()), dispatch(getLoyaltyPoints())]);
      return addMultipleSaveForLater;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getFreeProducts = createAsyncThunk(
  'cart/getFreeProducts',
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await cartServices.getFreeProducts(getState());
      return response.data.getAvailableFreeGiftsList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addFreeProduct = createAsyncThunk(
  'cart/addFreeProduct',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const added = await cartServices.addFreeProducts(getState(), data);
      await Promise.all([dispatch(getFreeProducts())]);
      if (localStorage.getItem('token')) {
        await Promise.all([dispatch(getLoyaltyPoints())]);
      }
      return added.data.amPromoAddFreeGiftsToCart;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(setLoading(false));
    }
  },
);

export const retriveToCart = createAsyncThunk(
  'cart/retriveToCart',
  async (itemData, { getState, rejectWithValue, dispatch }) => {
    try {
      const retriveToCart = await cartServices.retriveToCart(itemData, getState());
      await Promise.all([dispatch(getFreeProducts())]);
      if (localStorage.getItem('token')) {
        await Promise.all([dispatch(getLoyaltyPoints())]);
      }
      return retriveToCart;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteSaveForLater = createAsyncThunk(
  'cart/deleteSaveForLater',
  async (itemData, { getState, rejectWithValue }) => {
    try {
      return await cartServices.deleteSaveForLater(itemData, getState());
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateCartForOffline = createAsyncThunk(
  'cart/updateCartForOffline',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const updateCartForOffline = await cartServices.updateCartForOffline(getState());
      await Promise.all([dispatch(getFreeProducts())]);
      if (localStorage.getItem('token')) {
        await Promise.all([dispatch(getLoyaltyPoints())]);
      }
      return updateCartForOffline;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetCart: () => initialState,
    setCartID: (state, action) => {
      state.cartID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEmptyCart.fulfilled, (state, { payload }) => {
        state.cart = [];
        state.cartCount = 0;
        state.cartID = payload;
        if (!localStorage.getItem('token')) {
          localStorage.setItem('cartID', payload);
        }
        localStorage.removeItem('vpCartID');
        state.loading = false;
        state.status = 'success';
        state.message = '';
      })
      .addCase(createEmptyCart.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(createEmptyCart.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(getCustomerCartId.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(getCustomerCartId.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
        const error = payload.split(' ');
        if (error.includes('account') || error.includes('locked') || error.includes('logged')) {
          notification.error(payload);
          localStorage.removeItem('token');
        }
      })
      .addCase(getCustomerCartId.fulfilled, (state, { payload }) => {
        if (payload.company) {
          if (payload.customer?.panDetails?.pan_number !== null) {
            if (
              payload?.customer?.gstDetails === null ||
              payload?.customer?.gstDetails?.customer_gstin === null
            ) {
              state.hasNoGstNum = true;
            }
          }

          state.loading = false;
          state.userFirstName = payload?.customer?.firstname;
          state.userLastName = payload?.customer?.lastname;
          state.userMobileNumber = payload?.company.legal_address?.telephone;
          state.userEmail = payload?.company?.email;
          state.userID = payload?.customer?.id;
          state.status = 'success';
          state.message = '';
          state.cartID = payload.customerCart.id;
          localStorage.setItem('cartID', payload.customerCart.id);

          const perc = getProfileCompletion(
            payload?.company.legal_name,
            undefined,
            payload?.company.legal_address?.telephone,
            payload?.company?.email,
            undefined,
            undefined,
            payload.customer.emailVerified,
            `${
              payload.company.company_admin.customer_gstin ??
              payload?.customer?.panDetails?.pan_number
            }`,
            `${
              payload?.company?.legal_address?.street?.[0] ??
              payload?.customer?.panDetails?.type_of_holder
            }`,
          );

          state.profileCompletion = perc;
          return;
        }

        const { firstname, lastname, mobilenumber, email, dob, gender, emailVerified, id, orders } =
          payload.customer;
        state.loading = false;
        state.userFirstName = firstname;
        state.userLastName = lastname;
        state.userMobileNumber = mobilenumber;
        state.userEmail = email;
        state.userGender = gender;
        state.userDob = dob;
        state.userID = id;
        state.status = 'success';
        state.message = '';
        state.cartID = payload.customerCart.id;
        state.reserveOrderId = payload.reserve_order_id;
        localStorage.setItem('cartID', payload.customerCart.id);
        const perc = getProfileCompletion(
          firstname,
          lastname,
          mobilenumber,
          email,
          dob,
          gender,
          emailVerified,
        );
        state.profileCompletion = perc;
        state.totalOrdered = orders.total_count;
      })
      .addCase(assignCustomerCart.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
        state.hasfetchedCartdetails = false;
      })
      .addCase(assignCustomerCart.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
        state.status = 'rejected';
        state.hasfetchedCartdetails = false;
        // state.cart = [];
      })
      .addCase(assignCustomerCart.fulfilled, (state, { payload }) => {
        state.hasfetchedCartdetails = true;
        const [gift] = payload.available_gift_wrappings;
        state.cart = payload.items;
        state.priceDetails = payload.prices;
        state.usedPointsInValue = payload.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.amasty_rewards_used_points.used_points;
        state.giftWrapping = payload.gift_wrapping;
        state.appliedCoupons = payload.prices.applied_coupon_code.applied_coupon_codes;
        state.rewardPoints = payload.reward_points_will_earn;
        state.shippingAddress = payload.shipping_addresses;
        state.promoCredit = payload.applied_store_credit;
        state.gbWallet = payload.applied_gb_wallet;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.items);
        state.giftOption = gift ?? {};
        state.cartCount = payload.total_quantity;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(getCustomerCartCount.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(getCustomerCartCount.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(getCustomerCartCount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = 'pending';
        state.cartCount = payload;
      })
      .addCase(addToCart.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
        // state.loadin = true;
      })
      .addCase(addToCart.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
        state.swirlError = payload;
        // state.loadin = false;
        notification.error('Oops! The product could not be added to cart. Please try again');
      })
      .addCase(addToCart.fulfilled, (state, { payload }) => {
        state.cartCount = payload.cart.total_quantity;
        state.priceDetails = payload.cart.prices;
        state.loading = false;
        state.status = 'success';
        state.cart = payload?.cart?.items;
        state.swirlError = payload.user_errors?.[0]?.message || '';

        if (payload?.user_errors?.length === 0) {
          notification.success('Yay! Product added to cart');
        }
      })
      .addCase(addMultipleToCart.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
        // state.loadin = true;
      })
      .addCase(addMultipleToCart.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
        // state.loadin = false;
        notification.error('Oops! The product could not be added to cart. Please try again');
      })
      .addCase(addMultipleToCart.fulfilled, (state, { payload }) => {
        state.cartCount = payload.cart.total_quantity;
        state.priceDetails = payload.cart.prices;
        state.loading = false;
        state.status = 'success';
        state.cart = payload?.cart?.items;
      })

      .addCase(addToCartWithQuantity.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
        // state.loadin = true;
      })
      .addCase(addToCartWithQuantity.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
        // state.loadin = false;
        notification.error('Oops! The product could not be added to cart. Please try again');
      })
      .addCase(addToCartWithQuantity.fulfilled, (state, { payload }) => {
        state.cartCount = payload.cart.total_quantity;
        state.priceDetails = payload.cart.prices;
        state.loading = false;
        state.status = 'success';
        state.cart = payload?.cart?.items;
      })
      .addCase(getOnlyCartDetails.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(getOnlyCartDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(getOnlyCartDetails.fulfilled, (state, { payload }) => {
        state.cartCount = payload.total_quantity;
        state.cart = payload.items;
        state.priceDetails = payload.prices;
        state.totalSavings = payload.total_savings;
        state.usedPointsInValue = payload.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.amasty_rewards_used_points.used_points;
        state.giftWrapping = payload.gift_wrapping;
        state.appliedCoupons = payload.prices.applied_coupon_code.applied_coupon_codes;
        state.rewardPoints = payload.reward_points_will_earn;
        state.promoCredit = payload.applied_store_credit;
        state.gbWallet = payload.applied_gb_wallet;
        state.freeShipping = payload.free_shipping.status;
        state.shippingAmount = payload.free_shipping.amount;
        state.shippingCharge = payload.free_shipping.shipping_amount;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.items);
        const [gift] = payload.available_gift_wrappings;
        state.giftOption = gift ?? {};
        state.shippingAddress = payload.shipping_addresses;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(getCartDetails.pending, (state) => {
        state.hasfetchedCartdetails = false;
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(getCartDetails.rejected, (state, { payload }) => {
        state.hasfetchedCartdetails = true;
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(getCartDetails.fulfilled, (state, { payload }) => {
        state.hasfetchedCartdetails = true;
        state.cartCount = payload.total_quantity;
        state.cart = payload.items;
        state.priceDetails = payload.prices;
        state.totalSavings = payload.total_savings;
        state.usedPointsInValue = payload.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.amasty_rewards_used_points.used_points;
        state.giftWrapping = payload.gift_wrapping;
        state.appliedCoupons = payload.prices.applied_coupon_code.applied_coupon_codes;
        state.rewardPoints = payload.reward_points_will_earn;
        state.promoCredit = payload.applied_store_credit;
        state.gbWallet = payload.applied_gb_wallet;
        state.freeShipping = payload.free_shipping.status;
        state.shippingAmount = payload.free_shipping.amount;
        state.shippingCharge = payload.free_shipping.shipping_amount;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.items);
        const [gift] = payload.available_gift_wrappings;
        state.giftOption = gift ?? {};
        state.shippingAddress = payload.shipping_addresses;
        state.hasGlobalBrand = payload.has_global_brand;
        state.reserveOrderId = payload.reserve_order_id;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(cartDetailsOnFirstLoad.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
        state.hasfetchedCartdetails = false;
      })
      .addCase(cartDetailsOnFirstLoad.rejected, (state, { payload }) => {
        state.hasfetchedCartdetails = true;
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(cartDetailsOnFirstLoad.fulfilled, (state, { payload }) => {
        state.hasfetchedCartdetails = true;
        state.cartCount = payload.total_quantity;
        state.cart = payload.items;
        state.priceDetails = payload.prices;
        state.totalSavings = payload.total_savings;
        state.usedPointsInValue = payload.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.amasty_rewards_used_points.used_points;
        state.giftWrapping = payload.gift_wrapping;
        state.appliedCoupons = payload.prices.applied_coupon_code.applied_coupon_codes;
        state.rewardPoints = payload.reward_points_will_earn;
        state.promoCredit = payload.applied_store_credit;
        state.gbWallet = payload.applied_gb_wallet;
        state.freeShipping = payload.free_shipping.status;
        state.shippingAmount = payload.free_shipping.amount;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.items);
        const [gift] = payload.available_gift_wrappings;
        state.giftOption = gift ?? {};
        state.shippingAddress = payload.shipping_addresses;
        state.shippingCharge = payload.free_shipping.shipping_amount;
        state.saveForLater = payload.saveLater;
        state.gstEarn = payload.gst_will_earn;
        state.displayVolumeDiscount = payload.display_volume_discount;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(removeFromCart.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(removeFromCart.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
        notification.error('Item could not be removed from cart. Please try again');
      })
      .addCase(removeFromCart.fulfilled, (state, { payload }) => {
        state.cart = payload.cart.items;
        state.cartCount = payload.cart.total_quantity;
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.appliedCoupons = payload.cart.prices.applied_coupon_code.applied_coupon_codes;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.giftWrapping = payload.cart.gift_wrapping;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        const [gift] = payload.cart.available_gift_wrappings;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.shippingAddress = payload.cart.shipping_addresses;
        state.giftOption = gift ?? {};
        state.hasGlobalBrand = payload.cart.has_global_brand;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(updateCartItem.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(updateCartItem.rejected, (state, { payload }) => {
        state.status = 'rejected';
        state.message = payload;
        notification.error(payload.replace('ApolloError: ', ''));
      })
      .addCase(updateCartItem.fulfilled, (state, { payload }) => {
        state.cart = payload.cart.items;
        state.cartCount = payload.cart.total_quantity;
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.appliedCoupons = payload.cart.prices.applied_coupon_code.applied_coupon_codes;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.giftWrapping = payload.cart.gift_wrapping;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.shippingAddress = payload.cart.shipping_addresses;
        const [gift] = payload.cart.available_gift_wrappings;
        state.gstEarn = payload.cart.gst_will_earn;
        state.giftOption = gift ?? {};
        state.status = 'success';
      })
      .addCase(getLoyaltyPoints.pending, (state) => {
        // state.loading = true;
        state.status = 'pending';
      })
      .addCase(getLoyaltyPoints.rejected, (state, { payload }) => {
        // state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(getLoyaltyPoints.fulfilled, (state, { payload }) => {
        state.loyaltyPoints = payload.balance;
        // state.loading = false;
        state.status = 'success';
      })
      .addCase(getCartCoupons.pending, (state) => {
        // state.loading = true;
        state.status = 'pending';
      })
      .addCase(getCartCoupons.rejected, (state, { payload }) => {
        // state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(getCartCoupons.fulfilled, (state, { payload }) => {
        state.coupons = payload;
        // state.loading = false;
        state.status = 'success';
        state.couponErrMsg = '';
      })
      .addCase(applyCartCoupons.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(applyCartCoupons.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload?.replace('ApolloError:', '');
        state.couponErrMsg = state.message;
      })
      .addCase(applyCartCoupons.fulfilled, (state, { payload }) => {
        state.cart = payload.cart.items;
        state.cartCount = payload.cart.total_quantity;
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.appliedCoupons = payload.cart.prices.applied_coupon_code.applied_coupon_codes;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.couponErrMsg = '';
        state.loading = false;
        state.status = 'success';
        notification.success('Yay! Coupon applied successfully');
      })

      .addCase(applyMultipleCartCoupons.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(applyMultipleCartCoupons.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload?.replace('ApolloError:', '');
        state.couponErrMsg = state.message;
      })
      .addCase(applyMultipleCartCoupons.fulfilled, (state, { payload }) => {
        state.cart = payload.cart.items;
        state.cartCount = payload.cart.total_quantity;
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.appliedCoupons = payload.cart.prices.applied_coupon_code.applied_coupon_codes;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.gstEarn = payload.cart.gst_will_earn;
        state.couponErrMsg = '';
        state.loading = false;
        state.status = 'success';
      })
      .addCase(removeCartCoupons.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(removeCartCoupons.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload.replace('ApolloError:', '');
        notification.error('Oops! Something went wrong, Please try again');
      })
      .addCase(removeCartCoupons.fulfilled, (state, { payload }) => {
        state.cart = payload.cart.items;
        state.cartCount = payload.cart.total_quantity;
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.appliedCoupons = payload.cart.prices.applied_coupon_code.applied_coupon_codes;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.couponErrMsg = '';
        state.loading = false;
        state.status = 'success';

        if (payload.isUserAction) {
          notification.success('Coupon removed successfully!');
        }
      })
      .addCase(removeMultipleCartCoupons.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(removeMultipleCartCoupons.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload.replace('ApolloError:', '');
        notification.error('Oops! Something went wrong, Please try again');
      })
      .addCase(removeMultipleCartCoupons.fulfilled, (state, { payload }) => {
        state.cart = payload.cart.items;
        state.cartCount = payload.cart.total_quantity;
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.appliedCoupons = payload.cart.prices.applied_coupon_code.applied_coupon_codes;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.gstEarn = payload.cart.gst_will_earn;
        state.couponErrMsg = '';
        state.loading = false;
        state.status = 'success';

        if (payload.isUserAction) {
          notification.success('Coupon removed successfully!');
        }
      })
      .addCase(addGiftOptions.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(addGiftOptions.rejected, (state, { payload }) => {
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(addGiftOptions.fulfilled, (state, { payload }) => {
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.giftWrapping = payload.cart.gift_wrapping;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        const [gift] = payload.cart.available_gift_wrappings;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.giftOption = gift ?? {};
        state.status = 'success';
      })
      .addCase(removeGiftOptions.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(removeGiftOptions.rejected, (state, { payload }) => {
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(removeGiftOptions.fulfilled, (state, { payload }) => {
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.giftWrapping = payload.cart.gift_wrapping;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        const [gift] = payload.cart.available_gift_wrappings;
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.giftOption = gift ?? {};
        // state.loading = false;
        state.status = 'success';
      })
      .addCase(getCartStoreConfig.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(getCartStoreConfig.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(getCartStoreConfig.fulfilled, (state, { payload }) => {
        state.minimumShipping = payload;
        state.offer = payload.coupen_display_text;
        state.codAmount = parseInt(payload.cash_on_delivery_max_amount);
        state.razorpayAmount = parseInt(payload.razorpay_max_amount);
        state.loading = false;
        state.status = 'success';
      })
      .addCase(addLoyaltyPoints.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(addLoyaltyPoints.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
        notification.error('Oops! Something went wrong, Please try again.');
      })
      .addCase(addLoyaltyPoints.fulfilled, (state, { payload }) => {
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(addPromotionalAmount.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(addPromotionalAmount.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(addPromotionalAmount.fulfilled, (state, { payload }) => {
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.loading = false;
        state.status = 'success';
        notification.success('Your GlamourBook Cash discount was added successfully to the cart!');
      })
      .addCase(removePromotionalAmount.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(removePromotionalAmount.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(removePromotionalAmount.fulfilled, (state, { payload }) => {
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.loading = false;
        state.status = 'success';
        notification.success(
          'Your GlamourBook Cash discount was removed successfully from the cart!',
        );
      })
      .addCase(addGbWallet.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(addGbWallet.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(addGbWallet.fulfilled, (state, { payload }) => {
        state.priceDetails = payload.cart.prices;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.loading = false;
        state.status = 'success';
        notification.success('Your GlamourBook Vault discount was added successfully to the cart!');
      })
      .addCase(removeGbWallet.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(removeGbWallet.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(removeGbWallet.fulfilled, (state, { payload }) => {
        state.priceDetails = payload.cart.prices;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.loading = false;
        state.status = 'success';
        notification.success(
          'Your GlamourBook Vault discount was removed successfully from the cart!',
        );
      })
      .addCase(retriveCart.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(retriveCart.rejected, (state, { payload }) => {
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(retriveCart.fulfilled, (state, { payload }) => {
        state.cartCount = payload.total_quantity;
        state.cart = payload.items;
        state.priceDetails = payload.prices;
        state.totalSavings = payload.total_savings;
        state.usedPointsInValue = payload.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.amasty_rewards_used_points.used_points;
        state.giftWrapping = payload.gift_wrapping;
        state.appliedCoupons = payload.prices.applied_coupon_code.applied_coupon_codes;
        state.rewardPoints = payload.reward_points_will_earn;
        state.promoCredit = payload.applied_store_credit;
        state.gbWallet = payload.applied_gb_wallet;
        state.freeShipping = payload.free_shipping.status;
        state.shippingAmount = payload.free_shipping.amount;
        state.shippingCharge = payload.free_shipping.shipping_amount;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.items);
        const [gift] = payload.available_gift_wrappings;
        state.giftOption = gift ?? {};
        state.shippingAddress = payload.shipping_addresses;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(setShippingAddress.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(setShippingAddress.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(setShippingAddress.fulfilled, (state, { payload }) => {
        state.shippingAddress = payload.cart.shipping_addresses;
        // state.priceDetails = payload.prices;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(updateShippingAddress.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(updateShippingAddress.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(updateShippingAddress.fulfilled, (state, { payload }) => {
        state.shippingAddress = payload.shipping_addresses;
        state.priceDetails = payload.prices;
        state.walletCanUse = payload.promotional_wallet_can_use;
        state.promoCredit = payload.applied_store_credit;
        state.gbWallet = payload.applied_gb_wallet;
        state.totalSavings = payload.total_savings;
        state.gstEarn = payload.gst_will_earn;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(quickBuyBySku.pending, (state) => {
        state.loading = true;
      })
      .addCase(quickBuyBySku.rejected, (state, { payload }) => {
        notification.error(payload.replace('ApolloError:', ''));
        state.loading = false;
        state.message = payload;
      })
      .addCase(quickBuyBySku.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = '';
        state.cartID = payload.id;
        if (!payload.items.some((item) => item.__typename === VIRTUAL_CART_ITEM)) {
          localStorage.setItem('cartID', payload.id);
        }
        state.cartCount = payload.total_quantity;
        state.cart = payload.items;
        state.priceDetails = payload.prices;
        state.totalSavings = payload.total_savings;
        state.usedPointsInValue = payload.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.amasty_rewards_used_points.used_points;
        state.giftWrapping = payload.gift_wrapping;
        state.appliedCoupons = payload.prices.applied_coupon_code.applied_coupon_codes;
        state.rewardPoints = payload.reward_points_will_earn;
        state.promoCredit = payload.applied_store_credit;
        state.gbWallet = payload.applied_gb_wallet;
        state.freeShipping = payload.free_shipping.status;
        state.shippingAmount = payload.free_shipping.amount;
        state.shippingCharge = payload.free_shipping.shipping_amount;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.items);
        const [gift] = payload.available_gift_wrappings;
        state.giftOption = gift ?? {};
        state.shippingAddress = payload.shipping_addresses;
        state.status = 'success';
      })
      .addCase(addSaveForLater.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(addSaveForLater.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(addSaveForLater.fulfilled, (state, { payload }) => {
        state.cartCount = payload.cart.total_quantity;
        state.saveForLater = payload.cart.saveLater;
        state.cart = payload.cart.items;
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.giftWrapping = payload.cart.gift_wrapping;
        state.appliedCoupons = payload.cart.prices.applied_coupon_code.applied_coupon_codes;
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.gstEarn = payload.cart.gst_will_earn;
        const [gift] = payload.cart.available_gift_wrappings;
        state.giftOption = gift ?? {};
        state.shippingAddress = payload.cart.shipping_addresses;
        state.hasGlobalBrand = payload.cart.has_global_brand;
        state.loading = false;
        state.status = 'success';
        notification.success('Item moved to Save for Later successfully');
      })
      .addCase(addMultipleSaveForLater.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(addMultipleSaveForLater.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(addMultipleSaveForLater.fulfilled, (state, { payload }) => {
        state.cartCount = payload.cart.total_quantity;
        state.saveForLater = payload.cart.saveLater;
        state.cart = payload.cart.items;
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.giftWrapping = payload.cart.gift_wrapping;
        state.appliedCoupons = payload.cart.prices.applied_coupon_code.applied_coupon_codes;
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.gstEarn = payload.cart.gst_will_earn;
        const [gift] = payload.cart.available_gift_wrappings;
        state.giftOption = gift ?? {};
        state.shippingAddress = payload.cart.shipping_addresses;
        state.hasGlobalBrand = payload.cart.has_global_brand;
        state.loading = false;
        state.status = 'success';
        notification.success('Items moved to Save for Later successfully');
      })
      .addCase(retriveToCart.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(retriveToCart.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(retriveToCart.fulfilled, (state, { payload }) => {
        state.cartCount = payload.cart.total_quantity;
        state.saveForLater = payload.cart.saveLater;
        state.cart = payload.cart.items;
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.giftWrapping = payload.cart.gift_wrapping;
        state.appliedCoupons = payload.cart.prices.applied_coupon_code.applied_coupon_codes;
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.gstEarn = payload.cart.gst_will_earn;
        state.couponErrMsg = '';
        const [gift] = payload.cart.available_gift_wrappings;
        state.giftOption = gift ?? {};
        state.shippingAddress = payload.cart.shipping_addresses;
        state.hasGlobalBrand = payload.cart.has_global_brand;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(deleteSaveForLater.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(deleteSaveForLater.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(deleteSaveForLater.fulfilled, (state, { payload }) => {
        state.saveForLater = payload.cart.saveLater;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(updateCartForOffline.pending, (state) => {
        state.loading = true;
        state.status = 'pending';
      })
      .addCase(updateCartForOffline.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(updateCartForOffline.fulfilled, (state, { payload }) => {
        state.cartCount = payload.cart.total_quantity;
        state.saveForLater = payload.cart.saveLater;
        state.cart = payload.cart.items;
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
        state.usedPointsInValue = payload.cart.amasty_rewards_used_points.used_points_to_inr;
        state.usedPoints = payload.cart.amasty_rewards_used_points.used_points;
        state.giftWrapping = payload.cart.gift_wrapping;
        state.appliedCoupons = payload.cart.prices.applied_coupon_code.applied_coupon_codes;
        state.rewardPoints = payload.cart.reward_points_will_earn;
        state.promoCredit = payload.cart.applied_store_credit;
        state.gbWallet = payload.cart.applied_gb_wallet;
        state.freeShipping = payload.cart.free_shipping.status;
        state.shippingAmount = payload.cart.free_shipping.amount;
        state.shippingCharge = payload.cart.free_shipping.shipping_amount;
        state.bagDiscount = cartServices.calculateBagDiscount(payload.cart.items);
        state.gstEarn = payload.cart.gst_will_earn;
        state.couponErrMsg = '';
        const [gift] = payload.cart.available_gift_wrappings;
        state.giftOption = gift ?? {};
        state.shippingAddress = payload.cart.shipping_addresses;
        state.loading = false;
        state.status = 'success';
      })
      .addCase(getFreeProducts.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getFreeProducts.rejected, (state, { payload }) => {
        state.status = 'rejected';
        state.message = payload;
      })
      .addCase(getFreeProducts.fulfilled, (state, { payload }) => {
        state.availableGiftsQty = payload.available_gifts_qty;
        state.allowedQty = payload.allowed_qty;
        state.rules = payload.rules;
        state.status = 'success';
      })
      .addCase(addFreeProduct.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(addFreeProduct.rejected, (state, { payload }) => {
        state.status = 'rejected';
        state.message = payload;
        notification.error('Oops! The product could not be added to cart. Please try again');
      })
      .addCase(addFreeProduct.fulfilled, (state, { payload }) => {
        state.cartCount = payload.cart.total_quantity;
        state.status = 'success';
        state.cart = payload?.cart?.items;
        state.priceDetails = payload.cart.prices;
        state.totalSavings = payload.cart.total_savings;
      });
  },
});

export const { resetCart, setCartID } = cartSlice.actions;

export const getMinimumShipping = (state) => state.cart.minimumShipping;
export const getPriceDetails = (state) => state.cart.priceDetails;
export const getFreeShipping = (state) => state.cart.freeShipping;
export const getCartId = (state) => state.cart.cartID;
export const getShippingCharge = (state) => state.cart.shippingCharge;
export const getCartCount = (state) => state.cart.cartCount;
export const allDataExist = (state) => [
  state.cart.cart,
  state.cart.priceDetails,
  state.cart.freeShipping,
  state.cart.shippingAmount,
  state.cart.shippingAddress,
  state.cart.coupons,
  state.cart.loyaltyPoints,
];
export default cartSlice.reducer;
